import { gql, useQuery } from '@apollo/client';
import { useNotifications } from '../notifications/useNotifications';
import { useUserInfo } from '../user';

export const CATALOG_ITEM_BY_VERSIONED_ID = gql`
    query catalogItemByVersionedId($userId: ID!, $versionedId: ID!) {
        catalogItemByVersionedId(versionedId: $versionedId) {
            description
            duration {
                unit
                value
            }
            id
            languageCode
            name
            children {
                nodes {
                    id
                    name
                    trainingDeliveryUrl
                }
            }
            versionedId
            type {
                valueDisplayString
            }
        }
        transcriptByUserIdAndCatalogItemVersionedId(userId: $userId, catalogItemVersionedId: $versionedId) {
            digitalTranscriptStatus
            numberOfCompletedCourses
            registration {
                id
            }
            moduleDetails {
                status
                moduleLevelCatalogItemID
                moduleVersion
                version
            }
            completionDate
        }
    }
`;

export const useCatalogItemByVersionedId = (versionedId: string) => {
    const { user } = useUserInfo();
    const { showErrorMessage } = useNotifications();
    return useQuery(CATALOG_ITEM_BY_VERSIONED_ID, {
        variables: {
            userId: user.id,
            versionedId: versionedId,
        },
        skip: !user.id,
        onError: (error) => {
            showErrorMessage(error.message, `${versionedId}_error`);
        },
    });
};
