export const APPLICATION_NAME = 'AWS Cloud Institute';
export const PRIVACY_PAGE_LINK = 'https://aws.amazon.com/privacy';
export const TERMS_PAGE_LINK = 'https://aws.amazon.com/terms';
export const SKILLBUILDER_URL = 'https://skillbuilder.aws';
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const ALLOWED_USER_GROUPS = [
    'Padawan Invited',
    'Padawan PreActive',
    'Padawan Active',
    'Padawan Tech',
    'Padawan Paused',
    'Padawan Session Break',
    'Padawan Instructors',
];
export const LOGOUT_URL = 'https://login.us-east-1.auth.skillbuilder.aws/logout?client_id=eqr012grmkdgpoda07nr4h8ra';
export const AUTH_TOKEN_COOKIE = 'awsd2c-token-c';

export enum APPLICATION_URL {
    DEVELOPMENT = 'http://localhost:3000',
    BETA = 'https://beta.awscloudinstitute.skillbuilder.training.aws.dev',
    GAMMA = 'https://awscloudinstitute.skillbuilder.training.aws.dev',
    PROD = 'https://awscloudinstitute.skillbuilder.aws',
}

export const CURRICULUM_SERVICE_API_URL = 'homecomingOptions';

export enum RoleType {
    SERVICE = 'SERVICE',
    SUPER_USER = 'SUPER_USER',
    INTERNAL_EMPLOYEE = 'INTERNAL_EMPLOYEE',
    LEARNING_ADMIN = 'LEARNING_ADMIN',
    POST_AUTH_PRE_VIAS = 'POST_AUTH_PRE_VIAS',
    LEARNING_ACCOUNT_IT_CONTACT = 'LEARNING_ACCOUNT_IT_CONTACT',
    LEARNER = 'LEARNER',
}

export const DIGITAL_COURSE_NOT_ENROLLED = 'DIGITAL_COURSE_NOT_ENROLLED';

export const DeviceWidth = {
    Mobile: 480,
    Tablet: 768,
    Laptop: 1024,
    Desktop: 1200,
};

// Enrollment open/close dates in Hawaii time,
// pulled from https://w.amazon.com/bin/view/AWS_Training_and_Certification/AWS_Cloud_Institute/Team_Resources/Internal_Calendar
export const ENROLLMENT_IS_OPEN: Record<string, boolean> = {
    '2024-07-07T00:00:00.000-10:00': false,
    '2024-07-30T00:00:00.000-10:00': true,
    '2024-09-30T00:00:00.000-10:00': false,
    '2024-10-07T00:00:00.000-10:00': true,
    '2025-01-06T00:00:00.000-10:00': false,
    '2025-01-13T00:00:00.000-10:00': true,
    '2025-04-07T00:00:00.000-10:00': false,
    '2025-04-16T00:00:00.000-10:00': true,
    '2025-07-07T00:00:00.000-10:00': false,
    '2025-07-14T00:00:00.000-10:00': true,
    '2025-09-29T00:00:00.000-10:00': false,
    '2025-10-06T00:00:00.000-10:00': true,
    '2026-01-05T00:00:00.000-10:00': false,
    '2026-01-12T00:00:00.000-10:00': true,
    '2026-04-06T00:00:00.000-10:00': false,
    '2026-04-13T00:00:00.000-10:00': true,
    '2026-07-06T00:00:00.000-10:00': false,
    '2026-07-13T00:00:00.000-10:00': true,
    '2026-09-28T00:00:00.000-10:00': false,
};
export const LATE_REGISTRATION_GRACE_DAYS = 7;

/*
 * Defining the quarter dates
 * pulled from https://w.amazon.com/bin/view/AWS_Training_and_Certification/AWS_Cloud_Institute/Team_Resources/Internal_Calendar
 */

export const QUARTER_DATES: string[][] = [
    ['2024-01-08T00:00:00.000-10:00', '2024-04-06T00:00:00.000-10:00'],
    ['2024-04-08T00:00:00.000-10:00', '2024-07-06T00:00:00.000-10:00'],
    ['2024-07-08T00:00:00.000-10:00', '2024-09-28T00:00:00.000-10:00'],
    ['2024-09-30T00:00:00.000-10:00', '2025-01-04T00:00:00.000-10:00'],
    ['2025-01-06T00:00:00.000-10:00', '2025-04-15T00:00:00.000-10:00'],
    ['2025-04-07T00:00:00.000-10:00', '2025-07-05T00:00:00.000-10:00'],
    ['2025-07-07T00:00:00.000-10:00', '2025-09-27T00:00:00.000-10:00'],
    ['2025-09-29T00:00:00.000-10:00', '2026-01-03T00:00:00.000-10:00'],
    ['2026-01-05T00:00:00.000-10:00', '2026-04-04T00:00:00.000-10:00'],
    ['2026-04-06T00:00:00.000-10:00', '2026-07-04T00:00:00.000-10:00'],
    ['2026-07-06T00:00:00.000-10:00', '2026-09-26T00:00:00.000-10:00'],
    ['2026-09-28T00:00:00.000-10:00', '2027-01-02T00:00:00.000-10:00'],
    ['2027-01-04T00:00:00.000-10:00', '2027-04-03T00:00:00.000-10:00'],
    ['2027-04-05T00:00:00.000-10:00', '2027-07-03T00:00:00.000-10:00'],
    ['2027-07-05T00:00:00.000-10:00', '2027-09-25T00:00:00.000-10:00'],
    ['2027-09-27T00:00:00.000-10:00', '2028-01-01T00:00:00.000-10:00'],
];

export const MILLISECONDS = 1000;

const TIMEZONES: Record<string, string> = {
    'UTC+01:00': 'Europe/Paris',
    'UTC+02:00': 'Europe/Athens',
    'UTC+03:00': 'Europe/Moscow',
    'UTC+03:30': 'Asia/Tehran',
    'UTC+04:00': 'Asia/Dubai',
    'UTC+04:30': 'Asia/Kabul',
    'UTC+05:00': 'Asia/Karachi',
    'UTC+05:30': 'Asia/Kolkata',
    'UTC+05:45': 'Asia/Kathmandu',
    'UTC+06:00': 'Asia/Urumqi',
    'UTC+06:30': 'Asia/Yangon',
    'UTC+07:00': 'Asia/Bangkok',
    'UTC+08:00': 'Asia/Shanghai',
    'UTC+08:30': 'Asia/Pyongyang', // Unofficial
    'UTC+08:45': 'Australia/Eucla',
    'UTC+09:00': 'Asia/Tokyo',
    'UTC+09:30': 'Australia/Adelaide',
    'UTC+10:00': 'Australia/Sydney',
    'UTC+10:30': 'Australia/Lord_Howe',
    'UTC+11:00': 'Pacific/Norfolk',
    'UTC+12:00': 'Pacific/Auckland',
    'UTC+13:00': 'Pacific/Apia',
    'UTC+13:45': 'Pacific/Chatham', // Unofficial
    'UTC+14:00': 'Pacific/Kiritimati',
    'UTC-00:00': 'Europe/London',
    'UTC-01:00': 'Atlantic/Cape_Verde',
    'UTC-02:00': 'America/Noronha',
    'UTC-03:00': 'America/Sao_Paulo',
    'UTC-03:30': 'America/St_Johns',
    'UTC-04:00': 'America/Barbados',
    'UTC-05:00': 'America/New_York',
    'UTC-06:00': 'America/Chicago',
    'UTC-07:00': 'America/Denver',
    'UTC-08:00': 'America/Los_Angeles',
    'UTC-09:00': 'America/Anchorage',
    'UTC-09:30': 'Pacific/Marquesas',
    'UTC-10:00': 'Pacific/Honolulu',
    'UTC-11:00': 'Pacific/Pago_Pago',
};

export const getTimezoneFromUtcOffset = (utcOffset: string) => {
    return TIMEZONES[utcOffset] ?? DEFAULT_TIMEZONE;
};
