import { Box, ColumnLayout, ContentLayout, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { Skeleton } from '@amzn/sbux-lib';
import React, { useEffect } from 'react';
import { APPLICATION_NAME, getManagePaymentUrl, isAciEnrollmentOpenNow, productCardFromTranscript } from '../../common';
import { Calendar, getCurrentQuarterCourses, getDashboardState, getNextQuarterCourses, NotRegistered } from '../../components';
import { useTranscriptData } from '../../context/TranscriptContext';
import { useUserInfo } from '../../hooks';
import AppStrings from '../../resources/strings';

const DashboardContent = () => {
    document.title = APPLICATION_NAME;
    const { user } = useUserInfo();
    const { transcripts, loading } = useTranscriptData();
    const { dashboardPage } = AppStrings;

    useEffect(() => {
        const containerElement = document.getElementsByClassName('amazon-cloud-institute-layout')?.[0];
        containerElement?.classList?.add('gradient');
        return () => {
            containerElement?.classList?.remove('gradient');
        };
    });

    const dashboardState = getDashboardState(transcripts);

    const YourCoursesSection = () => {
        const currentQuarterCourses = getCurrentQuarterCourses(transcripts);
        return (
            <SpaceBetween size='l' data-testid='your-courses-section'>
                <Header variant='h2' data-testid='your-courses'>
                    {dashboardPage.yourCourses}
                    {currentQuarterCourses.length ? ` (${currentQuarterCourses.length})` : ''}
                </Header>
                <ColumnLayout columns={3}>{currentQuarterCourses.map(productCardFromTranscript)}</ColumnLayout>
            </SpaceBetween>
        );
    };

    const NextQuarterCoursesSection = () => {
        const nextQuarterCourses = getNextQuarterCourses(transcripts);
        return (
            <SpaceBetween size='l' data-testid='next-quarter-courses-section'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    data-testid='next-quarter'
                >
                    <div>
                        <Header variant='h2'>{`${dashboardPage.nextQuarter} (${nextQuarterCourses.length})`}</Header>
                    </div>
                </div>
                {nextQuarterCourses.length === 0 ? (
                    <NotRegistered />
                ) : (
                    <ColumnLayout columns={3}>{nextQuarterCourses.map(productCardFromTranscript)}</ColumnLayout>
                )}
            </SpaceBetween>
        );
    };

    const renderDashboardSections = () => {
        if (loading) {
            return <Skeleton isLoading height={800} type='image' width='100%' borderRadius={20} />;
        }

        if (dashboardState.notRegistered) {
            return <NotRegistered />;
        }
        return (
            <>
                <Calendar schedule={dashboardState.schedule ?? { events: [] }} />
                {dashboardState.isYourCoursesVisible && <YourCoursesSection />}
                {isAciEnrollmentOpenNow() && <NextQuarterCoursesSection />}
            </>
        );
    };

    return (
        <div id='dashboard-content'>
            <ContentLayout>
                <SpaceBetween size='l'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '40px',
                        }}
                    >
                        <div>
                            <Header variant='h1'>
                                {user.firstName ? `${dashboardPage.welcomeBack}${user.firstName}` : dashboardPage.welcomeUnknown}
                            </Header>
                        </div>
                        {/**
                         * Need to display manage payment link if the user is registered for courses in current / future quarters
                         * dashboardState.notRegistered denotes that user is not registered to any course for which we render a different component
                         *
                         */}
                        <Box float='right'>
                            <SpaceBetween size='l' direction='horizontal'>
                                <Link href={getManagePaymentUrl()}>{dashboardPage.manageEnrollment}</Link>
                            </SpaceBetween>
                        </Box>
                    </div>

                    {renderDashboardSections()}
                </SpaceBetween>
                <div style={{ height: '40px' }}></div>
            </ContentLayout>
        </div>
    );
};

export const Dashboard = () => {
    return <>{DashboardContent()}</>;
};
