import { Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import AppStrings from '../../resources/strings';
import React from 'react';
import styled from 'styled-components';
import { getRegistrationManagementUrl } from '../../common';

export enum CourseType {
    LEARNING_ACTIVITY_GROUP = 'LEARNING_ACTIVITY_GROUP',
    LEARNING_ACTIVITY = 'LEARNING_ACTIVITY',
}
export interface NotRegisteredProps {
    courseType?: CourseType;
}

const CustomContainer = styled(Container)`
    border: none !important;
`;

export const NotRegistered = ({ courseType = CourseType.LEARNING_ACTIVITY_GROUP }: NotRegisteredProps) => {
    let strings = AppStrings.dashboardPage.courseNotRegistered;
    let imageSource = '/public/dog_rocket.svg';
    if (courseType === CourseType.LEARNING_ACTIVITY) {
        strings = AppStrings.dashboardPage.sessionNotRegistered;
        imageSource = '/public/dog_detective.svg';
    }

    return (
        <CustomContainer>
            <SpaceBetween alignItems='center' size='xs'>
                <img {...(strings?.imageAltText && { alt: strings.imageAltText })} src={imageSource} width='350' />
                <Header variant='h2'>{strings.headerTitle}</Header>
                <p>{strings.description}</p>
                {strings.buttonLabel && (
                    <div>
                        <Button href={getRegistrationManagementUrl()}>{strings.buttonLabel}</Button>
                    </div>
                )}
            </SpaceBetween>
        </CustomContainer>
    );
};
