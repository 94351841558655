import { createEvents } from 'ics';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import AppStrings from '../../resources/strings';
import {
    AciCourseLoadTranscriptStatus,
    AciTranscript,
    ClassSchedule,
    DashboardState,
    DeliverySession,
    EventSchedule,
    EventType,
    getCurrentQuarter,
    isWithinQuarter,
    LearningActivity,
    MILLISECONDS,
    RegistrationStatus,
} from '../../common';

export const numberOfDays = 5;
// 6 AM to 6 PM
export const visibleHours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const isSameDay = (date1: Date, date2: Date): boolean => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

export const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const getPreviousMonday = (date: Date) => {
    return moment(date).day('Monday').hour(0).minutes(0).toDate();
};

export const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
    });
};

export const formatTime = (hours: number, minutes = 0) => {
    let suffix = 'AM';
    if (hours >= 12) suffix = 'PM';
    if (hours > 12) hours -= 12;
    return minutes > 0 ? `${hours}:${minutes}${suffix}` : `${hours}${suffix}`;
};

export const getEventStartHour = (event: EventType) => {
    return Number(event.date.toLocaleTimeString('en-gb', { timeZone: event.timezone, hour: '2-digit' }));
};

export const getVisibleDays = (date: Date) => {
    const days = [];
    for (let i = 0; i < numberOfDays; i++) {
        days.push(addDays(date, i));
    }
    return days;
};

const convertScheduleToIcs = (schedule: EventSchedule) => {
    return schedule.events
        .filter((event) => event.date)
        .map((event) => ({
            title: event.title,
            description: event.description,
            duration: { ...(event.durationInMinutes && { minutes: event.durationInMinutes }) },
            start: event.date.getTime(),
        }));
};

export const exportCalendar = async (schedule: EventSchedule): Promise<any> => {
    const { dashboardPage } = AppStrings;
    const fileName = 'AWS_Cloud_Institute_Calendar.ics';
    const icsSchedule = convertScheduleToIcs(schedule);
    const { error, value } = createEvents(icsSchedule);
    if (error) {
        return Promise.reject({ message: dashboardPage.calendar.calendarExportError });
    } else if (!value) {
        return Promise.reject({ message: dashboardPage.calendar.calendarExportNoDataError });
    }
    const file = new File([value], fileName, { type: 'text/calendar' });
    const url = URL.createObjectURL(file);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
};

const getWindowWidth = () => {
    const { innerWidth } = window;
    return innerWidth;
};

export const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    useEffect(() => {
        const handleResize = () => setWindowWidth(getWindowWidth());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

export const getEventSchedule = (transcripts: AciTranscript[]) => {
    const schedule: EventSchedule = {
        events: [],
    };
    transcripts.forEach((transcript) => {
        const learningActivityGroup = transcript.learningActivityGroup;
        const { learningActivity } = transcript.registration;
        /** Filtering learning activities based on delivery_session_type. We dont want activities that have delivery_session_type set as null */
        learningActivity?.delivery_sessions
            ?.filter((session) => session.delivery_session_type)
            .forEach((deliverySession) => {
                const event: EventType = {
                    title: deliverySession.delivery_session_type,
                    // The LADS timestamp is in epoch seconds, but TypeScript's Date expects milliseconds
                    date: new Date(deliverySession.start_timestamp * MILLISECONDS),
                    durationInMinutes: (deliverySession.end_timestamp - deliverySession.start_timestamp) / 60,
                    description: learningActivityGroup.catalog_item.description,
                    learningActivityGroupId: learningActivityGroup.pk,
                    catalogItemName: learningActivityGroup.catalog_item.name,
                    // TODO: Maybe add a fallback URL?
                    url: deliverySession.v_ilt_info?.url ?? '',
                    timezone: transcript.timezone,
                };
                schedule.events.push(event);
            });
    });
    return schedule;
};

export const getNextQuarterCourses = (transcripts: AciTranscript[]): AciTranscript[] =>
    transcripts.filter(
        (transcript) =>
            transcript.aciTranscriptStatus === AciCourseLoadTranscriptStatus.NOT_STARTED &&
            transcript?.registration?.registrationStatus !== RegistrationStatus.WITHDRAWN,
    );

export const getCurrentQuarterCourses = (transcripts: AciTranscript[]): AciTranscript[] =>
    transcripts.filter(
        (transcript) =>
            (transcript.aciTranscriptStatus === AciCourseLoadTranscriptStatus.IN_PROGRESS ||
                transcript.aciTranscriptStatus === AciCourseLoadTranscriptStatus.COMPLETED) &&
            transcript.registration?.registrationStatus !== RegistrationStatus.WITHDRAWN,
    );

/*
 * TODO - Need to move this to a better place
 * returns the dashboard state & calendar events
 * @param transcripts - transcript data returned from TMS
 */
export const getDashboardState = (transcripts: AciTranscript[]): DashboardState => {
    /** Transcript updates the status to IN_PROGRESS for the registered LAGs when the quarter starts
     * If the courses are withdrawn, transcript wont update the status, hence, need to check the registration status
     */
    const currentQuarterRegisteredCourses = getCurrentQuarterCourses(transcripts);
    const nextQuarterCourses = getNextQuarterCourses(transcripts);

    /** if no course registered for the current & next quarter, then return notRegistered state*/
    if (currentQuarterRegisteredCourses.length === 0 && nextQuarterCourses.length === 0) {
        return {
            notRegistered: true,
        };
    }

    /** If user has registered for LAG in the current or next quarter, show calendar events (or empty weeks) */
    const eventSchedule = getEventSchedule(currentQuarterRegisteredCourses.concat(nextQuarterCourses));
    return {
        schedule: eventSchedule,
        isYourCoursesVisible: currentQuarterRegisteredCourses.length > 0,
    };
};

export const isCourseRegistered = (transcripts: AciTranscript[]) => {
    const currentQuarter = getCurrentQuarter() as [string, string];
    if (!currentQuarter.length) return false;

    const currentQuarterCourses = transcripts.filter((transcript) => {
        const { registration } = transcript;
        if (registration) {
            const { start_timestamp, end_timestamp } = transcript.learningActivityGroup;

            /** The LADS timestamp is in epoch seconds, but TypeScript's Date expects milliseconds */
            return isWithinQuarter(currentQuarter, new Date(start_timestamp * MILLISECONDS), new Date(end_timestamp * MILLISECONDS));
        }
        return false;
    });

    return currentQuarterCourses.some((transcripts) => transcripts.registration.registrationStatus === RegistrationStatus.REGISTERED);
};

// This function return a 2 dimensional list that represents the Calendar Grid. Every cell corresponds to 1 hour on the calendar.
// Event[i][j] contains the event on the i-th hour of the j-th day. If there is no event, it will be { empty: true }.
// If the cell is not supposed to be rendered (because the previous cell overflows), then it will be { skip: true } instead.
export const createCalendarGrid = (days: Date[], schedule: EventSchedule, isEnabled: (category: string) => boolean) => {
    const events = createEmptySchedule();
    for (let i = 0; i < days.length; i++) {
        const currentDay = schedule.events.filter((event) => isSameDay(event.date, days[i]));
        // If no events on the current day, then render all cells as empty
        if (!currentDay) {
            continue;
        }
        // For every visible hour, check if there is an event starting during that hour
        for (let j = 0; j < visibleHours.length; j++) {
            const currentEvent = currentDay.find((event) => getEventStartHour(event) === visibleHours[j]);
            if (currentEvent && isEnabled(currentEvent.catalogItemName)) {
                // Since the calendar is rendered row by row (instead of column by column) we invert the indices here
                events[j][i] = currentEvent;
                // Skip subsequent table cells if event is longer than 1 hour since they're merged with the current one
                // For example, if the event starts at 8:45 AM and is 90 minutes long, then we need to skip the cells for 9 & 10 AM
                for (let k = 1; k < (currentEvent.date.getMinutes() + currentEvent.durationInMinutes) / 60 && j + k < events.length; k++) {
                    events[j + k][i] = { skip: true };
                }
            }
        }
    }
    return events;
};

// Returns an n x m array where n is the number of visible hours & m is the number of visible days. All cells are empty by default
export const createEmptySchedule = () => {
    const events: any[][] = [];
    for (let i = 0; i < visibleHours.length; i++) {
        events[i] = [];
        for (let j = 0; j < numberOfDays; j++) {
            events[i][j] = { empty: true };
        }
    }
    return events;
};

// Returns a functions that returns a sorted list of events on a given day
export const filterEventsForDay = (schedule: EventSchedule, isEnabled: (category: string) => boolean) => {
    return (day: Date) =>
        schedule.events
            .filter((event) => isSameDay(event.date, day) && isEnabled(event.catalogItemName))
            .sort((event1, event2) => event1.date.getTime() - event2.date.getTime());
};

export const isEmptyWeek = (weekStart: Date, schedule: EventSchedule): boolean => {
    const weekEnd = addDays(weekStart, numberOfDays);
    return !schedule.events.some((event) => weekStart <= event.date && event.date <= weekEnd);
};

export const getClassItem = (learningActivity: LearningActivity, week: Date, timezone: string) => {
    // Find the sessions in the current week. If there are none, then use the 1st week of the quarter
    const relevantSessions = getSessionsInWeek(learningActivity.delivery_sessions, week);
    const instructors = relevantSessions.map((session) => session.instructors?.map((instructor) => instructor.name)).flat();

    // Construct unoptimized schedule, which creates 1 entry per class day
    const unoptimizedSchedule = getUnoptimizedSchedule(relevantSessions, timezone);

    // Merge class days if they have the same start time & duration
    const schedule = mergeClassSchedules(unoptimizedSchedule);

    return {
        id: learningActivity.pk,
        // Remove duplicates from instructor array
        instructors: Array.from(new Set(instructors)),
        // Convert the optimized schedule into string representation
        schedule: getScheduleAsStrings(schedule),
    };
};

export const mergeClassSchedules = (unoptimizedSchedule: ClassSchedule[]) => {
    const schedule: ClassSchedule[] = [];
    for (const session of unoptimizedSchedule) {
        // Check if there is another session with the same start time & duration
        const match = schedule.find(
            (existingSession) => existingSession.duration === session.duration && existingSession.start === session.start,
        );
        // If there is a match, then merge the days, otherwise create a new entry
        if (match) {
            match.days = match.days.concat(session.days);
        } else {
            schedule.push(session);
        }
    }
    return schedule;
};

export const getUnoptimizedSchedule = (sessions: DeliverySession[], timezone: string) => {
    return sessions
        .sort((session1, session2) => session1.start_timestamp - session2.start_timestamp)
        .map((session) => {
            const startDate = new Date(session.start_timestamp * MILLISECONDS);
            return {
                days: [startDate.getDay()], // 0 = Sunday, 6 = Saturday
                start: moment.tz(startDate, timezone).format('h:mma z'), // e.g. 10:30 AM PST
                duration: (session.end_timestamp - session.start_timestamp) / 60, // in Minutes
            };
        });
};

export const getSessionsInWeek = (deliverySessions: DeliverySession[], weekStart: Date) => {
    // Filter for the events in the selected week
    let weekEnd = addDays(weekStart, numberOfDays);
    let relevantSessions = deliverySessions.filter(
        (session) =>
            weekStart.getTime() <= session.start_timestamp * MILLISECONDS && session.end_timestamp * MILLISECONDS <= weekEnd.getTime(),
    );

    // If there are no events in the selected week (e.g. before quarter start), then default to the first week
    if (relevantSessions.length === 0) {
        weekStart = getPreviousMonday(new Date(deliverySessions[0].start_timestamp * MILLISECONDS));
        weekEnd = addDays(weekStart, numberOfDays);
        relevantSessions = deliverySessions.filter(
            (session) =>
                weekStart <= new Date(session.start_timestamp * MILLISECONDS) && new Date(session.end_timestamp * MILLISECONDS) <= weekEnd,
        );
    }
    return relevantSessions;
};

export const getScheduleAsStrings = (schedule: ClassSchedule[]) => {
    const result: { recurrenceDay: string; recurrenceTime: string }[] = [];

    schedule.forEach((session) => {
        // Create separate entries for each group of consecutive days
        let currentDays: number[] = [];
        const sortedDays = session.days.sort();
        sortedDays.forEach((day, index) => {
            currentDays.push(day);

            if (index === sortedDays.length - 1 || sortedDays[index + 1] !== day + 1) {
                let recurrenceDay = '';
                if (currentDays.length === 1) {
                    recurrenceDay = daysOfWeek[currentDays[0]];
                } else {
                    recurrenceDay = `${daysOfWeek[currentDays[0]]} - ${daysOfWeek[currentDays[currentDays.length - 1]]}`;
                }

                result.push({
                    recurrenceDay: recurrenceDay,
                    recurrenceTime: `${session.start} | ${session.duration} minutes`,
                });

                currentDays = [];
            }
        });
    });

    // Sort the result based on the first day in each entry
    return result.sort((a, b) => {
        const getDayIndex = (day: string) => {
            const firstDay = day.split(' - ')[0];
            return Object.values(daysOfWeek).indexOf(firstDay);
        };

        return getDayIndex(a.recurrenceDay) - getDayIndex(b.recurrenceDay);
    });
};
