import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { Button, Grid } from '@amzn/awsui-components-react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { ActionArea, CourseHeaderV2, enums, Skeleton } from '@amzn/sbux-lib';
import { useRouter } from '@amzn/xb-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    convertDigitalTranscriptStatus,
    COURSE_DETAIL_ROUTE,
    FEATURE_FLAG,
    generatePageHeaderMetaData,
    generateStringArgs,
    getParamValues,
    getString,
    HOME_ROUTE,
    DIGITAL_COURSE_NOT_ENROLLED,
    DigitalTranscriptStatus,
} from '../../common';
import { Tabs } from '../../components';
import {
    useAccessByUserIdAndCatalogItemId,
    useCatalogItemByVersionedId,
    useNotifications,
    useRegisterVersionedCatalogItem,
} from '../../hooks';
import AppStrings from '../../resources/strings';
import { useTranscriptData } from '../../context/TranscriptContext';
import { isRegisteredLAG } from '../../common/utils/transcriptUtils';
import { getModuleUrl } from '../../common/utils/getModuleUrl';

export const TrainingModule = () => {
    const { id: catalogItemVersionedId } = useParams<{ id: string }>();
    const { useSearchParams } = useRouter();
    const searchParams = useSearchParams();
    const lagId = getParamValues(searchParams, 'lagId');
    const { transcripts } = useTranscriptData();

    /** Check if the user has registered to the LAG associated with this digital course */
    const isCourseRegistered = isRegisteredLAG(transcripts, lagId);

    const catalogItemId = catalogItemVersionedId?.split(':')[0];
    const { showErrorMessage, showSuccessMessage } = useNotifications();
    const { push } = useRouter();
    const { catalogPage: pageStrings } = AppStrings;

    const { data: access } = useAccessByUserIdAndCatalogItemId(catalogItemId!);
    if (
        FEATURE_FLAG.CATALOG_ITEM_ACCESS_CONTROL &&
        access &&
        access.accessByUserIdAndCatalogItemId &&
        access.accessByUserIdAndCatalogItemId.accessType !== 'AUTHORIZED'
    ) {
        showErrorMessage('You are not authorized to access this course.', 'authorization_error');
        push(HOME_ROUTE);
    }

    const { data, loading } = useCatalogItemByVersionedId(catalogItemVersionedId!);
    const course = data?.catalogItemByVersionedId;
    const transcript = data?.transcriptByUserIdAndCatalogItemVersionedId;
    /** default state of the transcript status is set to `NOT_ENROLLED`  */
    const [transcriptStatus, setTranscriptStatus] = useState<enums.States | typeof DIGITAL_COURSE_NOT_ENROLLED>(enums.States.NOT_ENROLLED);

    useEffect(() => {
        /** update the state and set it custom `DIGITAL_COURSE_NOT_ENROLLED` if the LAG associated with the digital course is registered  */
        if (isCourseRegistered && transcriptStatus !== DIGITAL_COURSE_NOT_ENROLLED) {
            setTranscriptStatus(DIGITAL_COURSE_NOT_ENROLLED);
        }
    }, [isCourseRegistered]);

    useEffect(() => {
        const digitalTranscript = convertDigitalTranscriptStatus(
            data?.transcriptByUserIdAndCatalogItemVersionedId?.digitalTranscriptStatus,
        );
        /** update the transcript status ONLY if the LAG is not registered OR the transcript status is other than the default NOT_ENROLLED  */
        if (!(digitalTranscript === enums.States.NOT_ENROLLED && isCourseRegistered)) {
            setTranscriptStatus(digitalTranscript);
        }
    }, [data]);
    // @ts-ignore
    const actionAreaStrings = pageStrings.headerActionArea[transcriptStatus];

    // Get the URL for the first incomplete module if NOT_STARTED or IN_PROGRESS or default to the first module if course is COMPLETED
    const actionAreaDisplayedModule: string | undefined =
        transcript?.moduleDetails?.find((m: { status: DigitalTranscriptStatus }) => m?.status !== DigitalTranscriptStatus.Completed)
            ?.moduleLevelCatalogItemID || transcript?.moduleDetails?.[0]?.moduleLevelCatalogItemID;

    const actionAreaDisplayedModuleName = course?.children?.nodes?.find(
        (m: { id: string | undefined }) => m.id === actionAreaDisplayedModule,
    )?.name;

    const [register, { loading: registering }] = useRegisterVersionedCatalogItem(catalogItemVersionedId!);
    const registerClickHandler = () => {
        const trainingModuleUrl = getModuleUrl(course, actionAreaDisplayedModule, transcript?.registration?.id);
        if (transcriptStatus !== enums.States.ENROLLED) {
            /** Call register mutation only if the user is not enrolled */
            register().then((result) => {
                if (!result.errors) {
                    setTranscriptStatus(enums.States.ENROLLED);
                    showSuccessMessage(`Successfully registered for "${course?.name}"`, `${catalogItemVersionedId}_registeration`);
                    window.open(trainingModuleUrl, '_blank');
                }
            });
        } else {
            /** Else redirect */
            window.open(trainingModuleUrl, '_blank');
        }
    };

    const content = () => {
        return (
            <div style={{ backgroundColor: awsui.colorBackgroundContainerContent }}>
                {loading ? (
                    <Skeleton isLoading height={200} type='image' width='100%' borderRadius={20} />
                ) : (
                    <div
                        data-testid='course-page-header'
                        style={{
                            display: 'flex',
                            padding: '28px 0',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ maxWidth: '1152px', width: '100%' }}>
                            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                                <div>
                                    <CourseHeaderV2
                                        courseTitle={course?.name}
                                        selectedLanguage={course?.languageCode}
                                        metadataItems={generatePageHeaderMetaData(
                                            course?.type?.valueDisplayString,
                                            course?.duration.value,
                                            course?.languageCode,
                                        )}
                                    />
                                    {lagId && (
                                        <Button iconName='arrow-left' href={`${COURSE_DETAIL_ROUTE}/${lagId}?activeTab=trainings`}>
                                            {pageStrings.backToCourseOverview}
                                        </Button>
                                    )}
                                </div>
                                <ActionArea
                                    state={transcriptStatus === DIGITAL_COURSE_NOT_ENROLLED ? enums.States.ENROLLED : transcriptStatus}
                                    heading={actionAreaStrings.headerTitle}
                                    description={getString(
                                        actionAreaStrings.description,
                                        generateStringArgs(
                                            transcriptStatus,
                                            {
                                                courseTitle: course?.name,
                                                courseDetails: transcript?.moduleDetails,
                                                nextModuleName: actionAreaDisplayedModuleName,
                                                numberOfCompletedCourses: transcript?.numberOfCompletedCourses,
                                            },
                                            transcript?.completionDate,
                                        ),
                                    )}
                                    {...(actionAreaStrings.actionLabel && {
                                        primaryButton: {
                                            ariaLabel: actionAreaStrings.actionLabel,
                                            buttonLabel: actionAreaStrings.actionLabel,
                                            onClick: registerClickHandler,
                                            loading: registering,
                                            variant: 'normal',
                                            ...(actionAreaStrings.actionIcon && {
                                                iconName: actionAreaStrings.actionIcon,
                                            }),
                                        },
                                    })}
                                />
                            </Grid>
                        </div>
                    </div>
                )}

                <Tabs
                    tabs={[
                        {
                            id: 'details',
                            label: pageStrings.courseDetailsTabHeading,
                            content: (
                                <LiveComponent
                                    componentName='DetailsTab'
                                    experienceProviderName='productdetailpage'
                                    props={{ ids: { catalogItemVariantId: catalogItemId }, flavor: 'aci' } as any}
                                />
                            ),
                        },
                        {
                            id: 'outline',
                            label: pageStrings.courseOutlineTabHeading,
                            content: (
                                <LiveComponent
                                    componentName='OutlineTab'
                                    experienceProviderName='productdetailpage'
                                    props={{ ids: { catalogItemVariantId: catalogItemId }, flavor: 'aci' } as any}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        );
    };

    return <>{content()}</>;
};
